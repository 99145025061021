import axios from "axios";
import React, { useState } from "react";
import first from "../assets/Screen.png";
import second from "../assets/Screen-1.png";
import third from "../assets/Screen-2.png";
// import logo from "../assets/Navbar Brand.png";
export const Logo = () => (
  <svg
    width="44"
    height="41"
    viewBox="0 0 44 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M44 22.2786C44 12.702 36.1037 3.88131 26.5564 0.569659C26.697 1.40387 26.7702 2.26088 26.7702 3.13493C26.7702 5.37777 26.2882 7.50842 25.4217 9.42962C22.0249 5.10382 17.2463 1.90744 11.7475 0.5C11.888 1.33381 11.9612 2.19039 11.9612 3.06398C11.9612 8.9972 8.58777 14.1453 3.64794 16.7077C2.50798 17.299 1.28459 17.7526 0 18.0464V31.0405C0 36.2648 4.25016 40.5 9.49299 40.5H34.507C38.6698 40.5 42.2068 37.83 43.4874 34.1148C43.4934 34.0971 43.4994 34.0795 43.5053 34.0622C43.7754 33.2634 43.9414 32.4168 43.9871 31.5385C43.9968 31.1513 44 30.6694 44 30.0472V22.2786ZM24.397 24.9864C24.397 28.3169 21.6875 31.0168 18.3452 31.0168C15.0029 31.0168 12.2934 28.3169 12.2934 24.9864C12.2934 21.6559 15.0029 18.956 18.3452 18.956C21.6875 18.956 24.397 21.6559 24.397 24.9864ZM34.3646 31.0168C37.7069 31.0168 40.4164 28.3169 40.4164 24.9864C40.4164 21.6559 37.7069 18.956 34.3646 18.956C31.0223 18.956 28.3128 21.6559 28.3128 24.9864C28.3128 28.3169 31.0223 31.0168 34.3646 31.0168Z"
      fill="#08A3E3"
    />
  </svg>
);

export default function MainPage() {
  const [email, setEmail] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log("Submitting email:", email);
    try {
      const response = await axios.post(
        "https://excel-topaz.vercel.app/submit",
        { email }
      );
      console.log("Data submitted successfully:", response.data);
      alert("success");
      setEmail("");
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };
  return (
    <div className="main-page">
      <div className="main-top">
        <div className="main-top-logo">
          {/* <img src={logo} alt="" /> */}
          <Logo />
        </div>
      </div>

      <div className="main-info">
        <h1 className="main-info-title">
          Multiplayer monster battles <br /> with unique control mechanics
        </h1>
        <h3>Battle with your monster pets with players worldwide.</h3>
        <div className="mail-button-container">
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              className="mail-button-input"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit" className="mail-button-submit">
              Join waitlist
            </button>
          </form>
        </div>

        <h4>Join mailing list to become our beta tester</h4>
      </div>

      <div className="main-card">
        <h1>Swipe-to-move + Draw-to-attack</h1>

        <div className="main-card-container">
          <img src={first} alt="" />
          <img src={third} alt="" />
          <img src={second} alt="" />
        </div>
      </div>

      <div className="main-tester">
        <h2>Join 10,000+ beta testers</h2>

        <div className="mail-button-container">
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              className="mail-button-beta-input"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit" className="mail-button-beta-submit">
              Subscribe
            </button>
          </form>
        </div>
        <h5>Ombro: New World © 2024 Mystory AI, Inc.</h5>
      </div>
    </div>
  );
}
